
import { useEffect } from 'react'
import LOGO from "../assets/logo.png"
import Footer from './components/Footer'

function Privacy() {

    useEffect(() => {
        document.body.style.backgroundColor = "black"
    }, []);

    return (
        <>
            <div className="app">
                <div className="part_two" style={{ width: "900px" }}>
                    <div className="coming">
                        Dernière révision: 11 Novembre 2024
                    </div>
                    <img src={LOGO} alt="Dropora logo" className="logo" />
                    <div className="tags">
                        Politique de confidentialité
                        <hr />
                        Engagement RGPD Dropora
                    </div>
                    <div className="text">
                        1. Introduction
                    </div>
                    <div className="mintext">
                        Dropora respecte les informations personnelles des Utilisateurs de l'Application mobile, et prend grand soin pour protéger vos informations. Dropora met tout en œuvre pour restreindre les risques de perte, détérioration ou mauvaise utilisation de celles-ci.
                        <br />
                        Cette politique de protection des données personnelles vous indique quelles sont les informations vous concernant que nous collectons, comment nous les utilisons et quelles sont les mesures que nous prenons pour assurer leur protection. Cette Politique s'applique à toutes les informations fournies par vous-même, ou recueillies par nous lors de votre navigation sur nos sites et Applications, conformément à la réglementation en vigueur en France en matière de données personnelles telle qu'issue de la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, dite loi « Informatique et Libertés» et du Règlement UE 2016/679 du 25 mai 2018 sur la protection des données dit « RGPD ».
                        <br />
                        La présente Politique de Protection de la Vie Privée a pour vocation :
                    </div>
                    <div className="mintext">
                        - d'informer les personnes accédant aux services proposés sur nos sites et Applications (Ci-après les « les sites et Applications ») de la manière dont nous collectons, utilisons et partageons leurs données personnelles ;
                    </div>
                    <div className="mintext">
                        - d'informer les personnes Visiteur aux campagnes digitales (Ci-après les « Clients ») de la manière dont Dropora SAS collecte et traite leurs données personnelles pour le compte de ses Utilisateurs ;
                    </div>
                    <div className="mintext">
                        - d'informer tout internaute naviguant sur les sites et Applications des informations que nous recueillons grâce aux « cookies ».
                    </div>
                    <div className="text">
                        2. Sécurité
                    </div>
                    <div className="mintext">
                        Dropora assure le traitement de vos données en toute confidentialité et en toute sécurité en mettant en place les mesures techniques et organisationnelle nécessaires pour préserver dans des contions optimales leur sécurité, leur confidentialité et leur intégrité.
                    </div>
                    <div className="mintext">
                        Dropora SAS s'engage à traiter vos Données personnelles de manière :
                        <br />
                        - loyale,<br />
                        - licite,<br />
                        - transparente,<br />
                        - propotionnée,<br />
                        - pertinente,<br />
                        - dans le strict cadre des finalités poursuivies et annoncées,<br />
                        - pour la durée nécessaire aux traitements mis en place,<br />
                        - de façon sécurisée.<br />
                    </div>
                    <div className="mintext">
                        Nous traitons vos données à caractère personnel dans le respect du Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 (« RGPD ») et dans les conditions exposées ci-après. Une donnée à caractère personnel désigne toute information se rapportant à une personne physique identifiée ou identifiable. Nous collectons et traitons des données à caractère personnel dans le cadre de la fourniture de nos Services ou de la communication à propos de ces Services exclusivement, en stricte conformité avec le RGPD.
                    </div>
                    <div className="mintext">
                        En cas de violation de vos Données personnelles, Dropora SAS en notifie la violation en question à la CNIL dans les meilleurs délais, et, si possible 72 heures au plus tard après en avoir pris connaissance, à moins que la violation en question ne soit pas susceptible d'engendrer un risque pour les droits et libertés des Utilisateurs. Lorsqu'une violation de vos Données personnelles est susceptible d'engendrer un risque élevé pour les droits et libertés d'un Utilisateur, Dropora SAS en informe l'Utilisateur, dans les meilleurs délais, sous réserve des exceptions prévues à l'article 34 du RGPD.
                    </div>
                    <div className="mintext">
                        Nous collectons uniquement des données à caractère personnel adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées. Ainsi, il ne vous sera jamais demandé de renseigner des données à caractère personnel considérées comme « sensibles », telles que vos origines raciales ou ethniques, vos opinions politiques, philosophiques ou religieuses. En vous enregistrant sur le Site, vous nous autorisez à traiter vos données à caractère personnel conformément à la Charte. Si vous refusez les termes de cette Charte, veuillez-vous abstenir d'utiliser le Site ainsi que les Services.
                    </div>
                    <div className="mintext">
                        Seules les personnes strictement habilitées par Dropora auront accès à vos données. Aucun tiers non autorisé ne saurait y avoir accès, hors obligation légale ou unioniste incombant au responsable de traitement.
                    </div>
                    <div className="mintext">
                        Si vous utilisez un mot de passe sur nos sites et Applications, vous êtes tenu de le garder confidentiel. Ne le partagez pas avec une autre personne. Si vous pensez que votre mot de passe a été mal utilisé, veillez à nous en informer immédiatement.
                    </div>
                    <div className="text">
                        3. Informations personnelles collectées par Dropora
                    </div>
                    <div className="mintext">
                        <strong>3.1 Données collectées</strong>
                    </div>
                    <div className="mintext">
                        Nous utilisons vos données à caractère personnel pour permettre la mise en œuvre et la gestion des Services de l'application mobile et répondre à vos demandes spécifiques. Nous utilisons également vos données à caractère personnel dans le but d'exploiter et d'améliorer nos Services, notre application mobile et notre démarche. Ces informations sont utilisées uniquement par nos soins et nous permettent de mieux adapter nos Services à vos attentes. Si vous avez décidé de recevoir des courriels de notre part, vous recevrez alors des messages électroniques et alphanumériques portant sur nos services et promotions. Nous utiliserons alors les données à caractère personnel que vous avez fournies lors de votre enregistrement. Vous pouvez vous désabonner de ces envois à tout moment.
                    </div>
                    <div className="mintext">
                        Nous sommes susceptibles de recueillir et de conserver vos données à caractère personnel, notamment lorsque vous :
                        <br />
                        naviguez sur l'application mobile et le Site<br />
                        votre localisation<br />
                        créez un Compte<br />
                        payez pour un Service<br />
                        obtenez un cadeau<br />
                        vous inscrivez à notre Newsletter<br />
                        nous contactez<br />

                    </div>
                    <div className="mintext">
                        <strong>3.2 Navigation générale</strong>
                    </div>
                    <div className="mintext">
                        Les sites rassemblent des informations de navigation sur les zones des sites que les visiteurs visitent. Ces informations nous permettent de voir quelles zones de nos sites sont les plus visitées et nous aident à améliorer la qualité de votre expérience en ligne. Des informations supplémentaires telles que le type de domaine, la version du navigateur et le fournisseur de services peuvent être collectées, celles-ci fournissent des informations concernant l'utilisation générale de l'Application ou du Site.
                    </div>
                    <div className="mintext">
                        <strong>3.3 Informations personnelles que nous recueillons</strong>
                    </div>
                    <div className="mintext">
                        <strong>3.3.1 Données de connexion</strong>
                    </div>
                    <div className="mintext">
                        A chaque fois que vous vous connectez à notre site web ou notre application mobile, nous recueillons des données à caractère personnel telles que, notamment, votre adresse IP, la date et l'heure de connexion, ainsi que des informations sur le navigateur que vous utilisez.
                    </div>
                    <div className="mintext">
                        <strong>3.3.2 Données de navigation sur l'Application et le Site</strong>
                    </div>
                    <div className="mintext">
                        Nous recueillons également les informations permettant d'identifier de quelle façon vous accédez à l'Application mobile ou le Site, quelles sont les pages consultées et pendant combien de temps.
                    </div>
                    <div className="mintext">
                        <strong>3.3.3 Votre localisation</strong>
                    </div>
                    <div className="mintext">
                        Avec votre consentement (base légale), nous collecterons des informations sur votre localisation afin de vous proposer des points d'intérêts correspondant à votre profil (lieux à visiter) et de réaliser des statistiques ainsi que de récupérer des produits au sein de nos magasins partenaires. Vous pouvez activer et désactiver la localisation en utilisant les paramètres de votre système d'exploitation de votre appareil mobile. Néanmoins, lorsque que vous désactivez cette fonctionnalité, vous n'aurez accès qu'à des fonctionnalités limitées de l'Application et notamment, il nous sera impossible techniquement de collecter les informations relatives à votre localisation.
                    </div>
                    <div className="mintext">
                        <strong>3.3.4 Création d'un Compte</strong>
                    </div>
                    <div className="mintext">
                        L'accès à nos Services nécessite la création préalable d'un Compte. Conformément aux Conditions Générales d'Utilisation, il vous sera demandé de renseigner un certain nombre de données à caractère personnel lors de la création de votre Compte, notamment : nom, pays de résidence, adresse, sexe, date de naissance, adresse e-mail, adresse e-mail professionnelle, société, coordonnées bancaires, et toute autre information que vous choisissez de fournir. Par exemple, nous pouvons vous demander de fournir vos coordonnées afin d'enregistrer un compte chez nous, de vous abonner aux communications marketing de notre part et / ou de nous soumettre des demandes de renseignements. Nous collectons également des informations lorsque vous laissez des commentaires ou publiez un avis sur un service sur notre Application mobile
                    </div>
                    <div className="mintext">
                        <strong>3.3.5 Paiement</strong>
                    </div>
                    <div className="mintext">
                        Certains Services disponibles sur le Site ou l'Application mobile sont payants. A cette fin, vous acceptez que nous puissions avoir recours à des prestataires de services de paiement qui peuvent collecter vos données à caractère personnel dans le but de permettre le bon fonctionnement des services de traitement des paiements par carte de crédit. Pour régler votre achat, vous devez fournir vos coordonnées de facturation ainsi que vos coordonnées de paiement, c'est-à-dire : le numéro de votre carte de paiement le nom du titulaire de la carte la date de validité et les codes de sécurité Et ce afin de procéder au traitement de votre règlement. Il peut également vous être demandé de fournir le nom de votre opérateur télécom, le modèle de votre téléphone portable et un numéro de portable valide afin de pouvoir fournir les instructions d'achat directement à travers votre téléphone mobile. Nous conservons les détails de vos paiements, ainsi que les détails des achats que vous effectuez. Le détail des transactions est conservé chez le prestataire externe. Cette conservation est effectuée à des fins internes, notamment pour la comptabilité, la conformité et à des fins juridiques, conformément au paragraphe 5 de la présente Charte.
                    </div>
                    <div className="mintext">
                        <strong>3.3.6 Livraison de Drops</strong>
                    </div>
                    <div className="mintext">
                        Pour la livraison des Drops obtenus sur le Site, vous acceptez que nous puissions avoir recours à des prestataires qui peuvent collecter vos données à caractère personnel dans le but de permettre leur bon acheminement.
                    </div>
                    <div className="mintext">
                        <strong>3.3.7 Adhésion à notre Newsletter et système de notification par message texte</strong>
                    </div>
                    <div className="mintext">
                        Après la création de votre Compte et la validation de votre email ainsi que de votre téléphone mobile, vous pouvez donner votre consentement à la réception de nos lettres d'informations et notifications concernant de nouveaux produits, services et promotions, dans le cadre des Services. En tout état de cause, vous disposez du droit de retirer votre consentement à la réception de telles lettres d'information à tout moment et sans frais dans les conditions prévues.
                    </div>
                    <div className="mintext">
                        <strong>3.3.8 Contacts</strong>
                    </div>
                    <div className="mintext">
                        Afin de donner suite aux demandes que vous pourriez effectuer auprès de notre Service Client, de confirmer les informations et toutes les transactions sur votre Compte et vous donner des informations concernant les Services (par exemple, si vous perdez votre mot de passe ou si des changements sont effectués dans les Conditions d'utilisation), nous utiliserons vos nom, prénom et adresse email.
                    </div>
                    <div className="mintext">
                        <strong>3.3.9 Durée de conservation</strong>
                    </div>
                    <div className="mintext">
                        Dropora conserve vos données personnelles pendant la période nécessaire à la fourniture des Services. Ensuite, les données traitées à l'occasion de l'utilisation de l'Application et liées à la fourniture des Services sont conservées pendant une durée de cinq (5) ans à compter de la fermeture de votre compte, sauf si la loi exige que certaines de ces données soient conservées pour une durée distincte.
                    </div>
                    <div className="mintext">
                        <strong>3.3.10 Informations d'utilisation</strong>
                    </div>
                    <div className="mintext">
                        Lorsque vous utilisez les sites et Applications, certaines informations d'utilisation peuvent être collectées. Les informations que nous pouvons collecter comprennent les contenus créés via nos applications. Ces informations d'utilisation peuvent être traitées pour illustrer les sites et Applications, livrer des messages de service et autre type de contenus que vous demandez, et effectuer une analyse globale des performances des contenus créés via nos applications. Le fondement juridique de ce traitement est notre intérêt légitime à assurer l'exécution d'un contrat entre vous et nous.
                    </div>
                    <div className="text">
                        4. Protection des données personnelles
                    </div>
                    <div className="mintext">
                        Lorsque vous utilisez les sites et Applications, certaines informations d'utilisation peuvent être collectées. Les informations que nous pouvons collecter comprennent les contenus créés via nos applications. Ces informations d'utilisation peuvent être traitées pour illustrer les sites et Applications, livrer des messages de service et autre type de contenus que vous demandez, et effectuer une analyse globale des performances des contenus créés via nos applications. Le fondement juridique de ce traitement est notre intérêt légitime à assurer l'exécution d'un contrat entre vous et nous.
                    </div>
                    <div className="text">
                        5. Informations que nous partageons avec des tierces parties
                    </div>
                    <div className="mintext">
                        <strong>5.1 Auprès des équipes internes
                        </strong>
                    </div>
                    <div className="mintext">
                        Les Données personnelles collectées par Dropora SAS sont destinées aux personnes dûment habilitées à traiter les Données personnelles au sein de Dropora SAS, notamment, et selon la nature du traitement et le type de données, les personnes chargées du service commercial, du service client, du service marketing, du service administratif, du service logistique et informatique.
                    </div>
                    <div className="mintext">
                        <strong>5.2 Partage de vos données avec des sociétés tierces</strong>
                    </div>
                    <div className="mintext">
                        Lors de votre navigation sur Dropora, avec votre consentement, vos données à caractère personnel peuvent être transmises à des prestataires extérieurs. Ces tiers assurent un service pour notre compte et en notre nom dans le but de permettre le bon fonctionnement de l'Application mobile et autres Services.
                    </div>
                    <div className="mintext">
                        <strong>5.3 Pour afficher des publicités personnalisées</strong>
                    </div>
                    <div className="mintext">
                        Pour vous présenter des publicités personnalisées dans le cadre du Service, nous avons un intérêt légitime à traiter les données nécessaires afin de Suivre le contenu auquel vous accédez en lien avec le Service et votre comportement en ligne livrer, cibler et améliorer notre publicité et notre Service Pour des informations sur la manière dont vous vous désabonnez des services de publicités personnalisées.
                    </div>
                    <div className="mintext">
                        <strong>5.4 Pour des fins marketing</strong>
                    </div>
                    <div className="mintext">
                        Les informations personnelles que vous fournissez seront utilisées à des fins de marketing et de promotion uniquement par Dropora, son groupe et les sociétés liées et les sociétés tierces que nous avons spécialement sélectionnées pour vous offrir des Promotions, produits ou services, et conformément à vos préférences marketing. Nous ne louons et ne vendons pas autrement vos informations personnelles saisies sur l'Application mobile à des tierces parties.
                    </div>
                    <div className="mintext">
                        Nous faisons en sorte que les noms et les adresses postales de notre liste d'adresses postales, parfois catégorisées par informations d'achat, soient mises à la disposition de nos partenaires à des fins de marketing, à condition que vous ayez accepté de le faire.
                    </div>
                    <div className="mintext">
                        Vos informations personnelles peuvent être partagées avec d'autres sociétés du groupe et avec certains tiers uniquement pour permettre à ces tiers de nous assister dans le fonctionnement des Sites et de nos activités. Voici quelques exemples d'assistance que peuvent nous fournir des fournisseurs de services tiers: diffusion de publicités pertinentes, traitement des demandes, traitement des paiements, fourniture d'informations électroniques en ligne aux investisseurs, suivi des activités sur site, fourniture de services de prévention des fraudes, enquêtes, administration et tenue à jour des renseignements sur les demandes d'emploi, administration et envoi de messages par téléphone portable et administration des courriels.
                    </div>
                    <div className="mintext">
                        Lorsque vous soumettez une évaluation de produit sur Dropora, votre nom ou votre nom d'utilisateur peut être publié sur Dropora en même temps que votre avis.
                    </div>
                    <div className="mintext">
                        <strong>5.5 Partage avec les autorités</strong>
                    </div>
                    <div className="mintext">
                        Nous pouvons divulguer vos renseignements personnels à tout organisme d'application de la loi, organisme réglementaire, gouvernemental, tribunal ou autre tiers compétent lorsque nous croyons que la divulgation est nécessaire conformément à la loi ou à la réglementation applicable, afin d'exercer, établir ou défendre nos droits juridiques pour protéger vos intérêts vitaux ou ceux de toute autre personne.
                    </div>
                    <div className="mintext">
                        <strong>5.6 Dans le cadre d'un projet d'achat</strong>
                    </div>
                    <div className="mintext">
                        Dropora divulguera vos informations personnelles à un acheteur potentiel (et ses agents et conseillers) dans le cadre de tout projet d'achat, de fusion ou d'acquisition d'une partie de notre activité, à condition que nous informions l'acheteur qu'il doit utiliser vos informations personnelles uniquement à des fins divulguées dans cette politique de confidentialité.
                    </div>
                    <div className="text">
                        6. Publicité
                    </div>
                    <div className="mintext">
                        Dropora se réserve la faculté d'insérer sur toute page de l'Application mobile et dans toutes communication aux Utilisateurs tous messages publicitaires ou promotionnels sous une forme et dans des conditions dont Dropora sera seule juge.
                    </div>
                    <div className="text">
                        7. Informations que nous obtenons auprès de sources tierces
                    </div>
                    <div className="mintext">
                        De temps en temps, nous recevons des informations personnelles à votre sujet de sources tierces (y compris des marques partenaires), ou sont légalement autorisées ou tenues de nous divulguer vos informations personnelles.
                    </div>
                    <div className="mintext">
                        Les types de renseignements que nous recueillons auprès de tierces parties comprennent : nom, adresse électronique et date de naissance, et nous utilisons les informations que nous recevons de ces tierces parties afin de maintenir et améliorer l'exactitude des documents que nous détenons à votre sujet.
                    </div>
                    <div className="text">
                        8. Durée de conservation de vos données à caractère personnel
                    </div>
                    <div className="mintext">
                        Nous conserverons vos données à caractère personnel pendant une durée n'excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont traitées conformément aux utilisations exposées dans la présente Charte et dans le respect des lois et règlements. Nous déterminerons la durée de conservation en fonction des critères suivants:
                    </div>
                    <div className="mintext">
                        Informations comportementales telles que l'adresse IP de votre ordinateur, les temps d'accès, le type et la langue du navigateur, les données de localisation, la durée de la visite, les pages vues, les actions telles que les clics, la fréquence des visites et le renvoi des adresses des sites et Applications Web collectées via notre propre système de suivi sera retenu 6 mois.
                    </div>
                    <div className="mintext">
                        Les informations comportementales telles que le statut d'authentification ne seront conservées que pendant la session.
                    </div>
                    <div className="mintext">
                        Les informations de profil telles que le nom et l'adresse e-mail, les informations démographiques telles que le sexe, l'éducation, la date de naissance, les préférences et les intérêts, le numéro de téléphone, la langue et le pays, le poste occupé, le nom de l'entreprise et la taille de l'entreprise seront conservés aussi longtemps que nous devons les fournir. vous avec nos services les sites et Applications et 3 ans après la fin de l'utilisation de nos sites et Applications.
                    </div>
                    <div className="mintext">
                        Les informations de conversation telles que les messages, les courriers et les appels, telles que l'adresse e-mail, le numéro de téléphone, l'emplacement, l'enregistrement des appels, reçues de l'un de nos canaux de communication marketing, seront conservées aussi longtemps que nous aurons besoin de vous fournir nos services et 3 ans après la fin de l'utilisation de nos sites et Applications.
                    </div>
                    <div className="mintext">
                        Les informations transactionnelles seront conservées 10 ans après la fin de l'utilisation de nos sites et Applications.
                    </div>
                    <div className="text">
                        9. Les cookies
                    </div>
                    <div className="mintext">
                        <strong>9.1 Qu'est-ce qu'on cookie ?</strong>
                    </div>
                    <div className="mintext">
                        Un cookie est un fichier texte susceptible d'être déposé dans un terminal lors de la consultation d'un service en ligne avec un logiciel de navigation. Un fichier cookie permet notamment à son émetteur, pendant sa durée de validité, de reconnaître le terminal concerné à chaque fois que ce terminal accède à un contenu numérique comportant des cookies du même émetteur. En tout état de cause, les cookies déposés sur votre terminal de navigation avec votre accord sont détruits 13 mois après leur dépôt sur votre terminal.
                    </div>
                    <div className="mintext">
                        <strong>9.2 Comment nous utilisons les cookies ?</strong>
                    </div>
                    <div className="mintext">
                        Nous sommes susceptible de collecter automatiquement certaines informations par le biais de «cookies». L'utilisation de cookies nous aide à améliorer l'expérience de nos sites et Applications.
                    </div>
                    <div className="mintext">
                        Les cookies que nous émettons nous permettent :
                    </div>
                    <div className="mintext">
                        - d'établir des statistiques et volumes de fréquentation et d'utilisation des diverses éléments composant notre Site et Application mobile(rubriques et contenus visités, parcours), nous permettant d'améliorer l'intérêt et l'ergonomie de nos services
                    </div>
                    <div className="mintext">
                        - d'adapter la présentation de notre Site et Application mobile aux préférences d'affichage de votre terminal (langue utilisée, résolution d'affichage, système d'exploitation utilisé, etc) lors de vos visites sur notre Site, selon les matériels et les logiciels de visualisation ou de lecture que votre terminal comporte
                    </div>
                    <div className="mintext">
                        - nous utilisons des cookies pour nous aider à analyser l'utilisation et les performances des sites et Applications. Les cookies utilisés à cette fin sont: Google Analytics
                    </div>
                    <div className="mintext">
                        - nous utilisons des cookies pour nous aider à comprendre vos choix de cookies, Les cookies utilisés à cette fin sont: Cookie consent
                    </div>
                    <div className="mintext">
                        - de mémoriser des informations relatives à un formulaire que vous avez rempli sur notre Site et Application mobile (inscription ou accès à votre compte) ou à des produits, services ou informations que vous avez choisis sur notre Site et Application mobile (service souscrit, contenu d'un panier de commande, etc.)
                    </div>
                    <div className="mintext">
                        - de vous permettre d'accéder à des espaces réservés et personnels de notre Site et Application mobile, tels que votre compte, grâce à des identifiants ou des données que vous nous avez éventuellement antérieurement confiés et de mettre en œuvre des mesures de sécurité, par exemple lorsqu'il vous est demandé de vous connecter à nouveau à un contenu ou à un service après une certain laps de temps
                    </div>
                    <div className="mintext">
                        Lors de votre navigation sur le Site et Application mobile, des cookies des réseaux sociaux peuvent être générés notamment par l'intermédiaire des boutons de partage qui collectent des données à caractère personnel.
                        <br />
                        Lors de votre première visite sur le Site et Application mobile, un bandeau cookies apparaîtra en page d'accueil. Un lien cliquable permet d'en savoir plus sur la finalité et le fonctionnement des cookies et renvoie vers la présente Charte. La poursuite de la navigation sur une autre page du site ou la sélection d'un élément du Site (notamment : image, texte, lien, etc.) matérialise votre acceptation au dépôt des cookies visés sur votre ordinateur.
                    </div>
                    <div className="mintext">
                        <strong>9.3 Comment pouvez contrôler les cookies utilisés ?</strong>
                    </div>
                    <div className="mintext">
                        Vous pouvez à tout moment configurer votre logiciel de navigation de manière à ce que des cookies soient enregistrés dans votre terminal ou, au contraire, qu'ils soient rejetés (soit systématiquement, soit selon leur émetteur). Vous pouvez également configurer votre logiciel de navigation de manière à ce que l'acceptation ou le refus des cookies vous soient proposés ponctuellement, avant qu'un cookie puisse être enregistré dans votre terminal. Attention : tout paramétrage est susceptible de modifier votre navigation sur Internet et vos conditions d'accès à certains services nécessitant l'utilisation de cookies. Nous déclinons toute responsabilité s'agissant des conséquences liées au fonctionnement dégradé de nos services résultant de l'impossibilité d'enregistrer ou de consulter les cookies nécessaires à leur fonctionnement et que vous auriez refusés ou supprimés. Tel serait le cas si vous tentiez d'accéder à nos contenus ou services qui nécessitent de vous identifier. Tel serait également le cas lorsque nous (ou nos prestataires) ne pourrions pas reconnaître, à des fins de compatibilité technique, le type de navigateur utilisé par votre terminal, ses paramètres de langue et d'affichage ou le pays depuis lequel votre terminal semble connecté à Internet.
                        <br />
                        Pour obtenir plus d'information sur les cookies, vous pouvez consulter le site internet de la CNIL.
                    </div>
                    <div className="text">
                        10. Transfert(s) de donnée hors de l'Union Européenne
                    </div>
                    <div className="mintext">
                        Dans le cadre de la fourniture des Services, Dropora est susceptible de transférer vos données personnelles vers des pays non-membres de l'Union Européenne et qui n'ont pas fait l'objet d'une décision d'adéquation de protection de la Commission Européenne. Dans ce cas, Dropora s'assure que ce transfert soit réalisé dans des conditions conformes à la réglementation en vigueur en prenant toutes les mesures appropriées et dans un cadre juridique, technique et opérationnel propre à assurer la confidentialité et la sécurité de ces données.
                    </div>
                    <div className="mintext">
                        Conformément au RGPD, tous les transferts de données à caractère personnel vers un pays situé hors de l'Union Européenne et/ou n'offrant pas un niveau de protection considéré comme suffisant par la Commission européenne ont fait l'objet de conventions de flux transfrontière conformes aux clauses contractuelles types édictées par la Commission européenne et déclarées à la CNIL.
                    </div>
                    <div className="text">
                        11. Les pages des sites de réseaux sociaux de Dropora
                    </div>
                    <div className="mintext">
                        Les informations personnelles peuvent être collectées directement par les Sites via certaines pages de réseaux sociaux et peuvent également être collectées par le site de média social hébergeant la page des réseaux sociaux de Dropora, le cas échéant.
                        <br />
                        Les sites de réseaux sociaux où Dropora possède des profils/pages (Facebook ou Twitter par exemple) peuvent fournir des renseignements agrégés et des analyses à Dropora sur l'utilisation par les visiteurs de nos pages de réseaux sociaux. Cela nous permet de mieux comprendre et analyser la croissance de nos utilisateurs, les informations démographiques générales sur les utilisateurs de nos pages de réseaux sociaux, et l'interaction des utilisateurs avec le contenu de nos pages de réseaux sociaux. Dans l'ensemble, ces informations peuvent nous aider à comprendre les types de visiteurs et d'utilisateurs de nos pages de réseaux sociaux et leur utilisation du contenu.
                    </div>
                    <div className="text">
                        12. Droits concernant vos données personnelles
                    </div>
                    <div className="mintext">
                        Vous disposez de droits sur vos données à caractère personnel. Conformément à la réglementation en matière de protection des données à caractère personnel, notamment les articles 15 à 22 du RGPD, et après avoir justifié de votre identité, vous avez le droit de nous demander l'accès aux données à caractère personnel vous concernant, la rectification ou l'effacement de celles-ci.
                    </div>
                    <div className="mintext">
                        Vous pouvez en outre demander de vous opposer au traitement, de le limiter, de décider du sort post-mortem de vos données, de retirer votre consentement à tout moment et du droit à la portabilité des données à caractère personnel fournies dans les conditions de l'article 18 du RGPD.
                    </div>
                    <div className="mintext">
                        Vous pouvez contactez nos Services par email à <a href="mailTo:contact@dropora.com">contact@dropora.com</a>
                    </div>
                    <div className="mintext">
                        Conformément à l'article 21 du RGPD, vous avez la possibilité de vous opposer, à tout moment, au traitement de vos Données personnelles à des fins de prospection commerciale. Vous avez le droit de vous opposer au traitement de vos données à caractère personnel à des fins de marketing direct (y compris le profilage à des fins de marketing direct). Si vous faites une telle objection, nous cesserons de traiter vos données personnelles à cette fin. Vous pouvez refuser de recevoir des courriels promotionnels de notre part en suivant les instructions qui y figurent. Vous pouvez également envoyer des demandes relatives aux messages promotionnels et votre permission de partager des informations avec des tiers à des fins de marketing en nous envoyant un courrier électronique à l'adresse contact@dropora.com. Les demandes de retrait ne s'appliqueront pas aux messages de service transactionnel, y compris les messages concernant votre compte et vos services sur les sites et Applications actuels. Lorsque le traitement des informations personnelles vous concernant est fondé sur le consentement, vous avez le droit de le retirer à tout moment. Le retrait n'affectera pas la licité du traitement avant le retrait.
                    </div>
                    <div className="mintext">
                        Dans les circonstances prévues à l'article 20 du RGPD, vous pouvez récupérer auprès de Dropora SAS les Données personnelles que vous lui avez fournies, dans un format structuré, couramment utilisé et lisible par machine, aux fins de les transmettre à un autre responsable de traitement. Dans le cas où le fondement juridique de notre traitement de vos données à caractère personnel est le consentement ou lorsque le traitement est nécessaire à l'exécution d'un contrat auquel vous êtes partie ou à la suite de votre demande avant la conclusion du contrat, et Ce traitement est automatisé. Cependant, ce droit ne s'applique pas s'il porte atteinte aux droits et libertés d'autrui.
                    </div>
                    <div className="mintext">
                        Conformément à l'article 16 du RGPD, le droit de rectification vous confère le droit d'exiger de Dropora SAS que soient rectifiées, complétées ou mises à jour les Données personnelles vous concernant lorsqu'elles sont inexactes, incomplètes, équivoques ou périmées. Dans les conditions prévues à l'article 17 du RGPD, vous pouvez également demander l'effacement des données vous concernant. Vous avez le droit de faire rectifier les données personnelles inexactes qui vous concernent et, en tenant compte des finalités du traitement, de compléter les données personnelles incomplètes vous concernant.
                    </div>
                    <div className="mintext">
                        Dans certaines circonstances, vous avez le droit d'effacer vos données personnelles. Ces données incluent: les données à caractère personnel ne sont plus nécessaires au regard des finalités pour lesquelles elles ont été collectées ou traitées, vous retirez votre consentement à un traitement basé sur le consentement, vous vous opposez au traitement en vertu de certaines règles du droit de la protection des données applicable, le traitement est à des fins de marketing direct; et les données personnelles ont été traitées illégalement. Cependant, il existe des exclusions du droit d'effacement. Les exclusions générales incluent les cas où le traitement est nécessaire pour vous fournir nos services sur les sites et Applications.
                    </div>
                    <div className="mintext">
                        Si vous estimez que notre traitement de vos informations personnelles enfreint les lois sur la protection des données, vous avez le droit de porter plainte auprès d'une autorité de contrôle chargée de la protection des données. Vous pouvez le faire dans l'État membre de l'UE de votre résidence habituelle, de votre lieu de travail ou du lieu de l'infraction présumée.
                    </div>
                    <div className="mintext">
                        Dropora SAS fournira à la personne qui exerce l'un de ces droits des informations sur les mesures prises, dans les meilleurs délais et en tout état de cause dans un délai d'un 1 mois à compter de la réception de la demande. Ce délai peut être prolongé de deux 2 mois, eu égard à la complexité et au nombre de demandes.
                    </div>
                    <div className="mintext">
                        Si Dropora SAS ne donne pas suite à la demande, elle en informera la personne, dans les meilleurs délais, et au plus tard dans un délai d'un 1 mois à compter de la réception de sa demande des motifs de son inaction et de la possibilité d'introduire une réclamation auprès d'une autorité de contrôle et de former un recours juridictionnel.
                    </div>
                    <div className="mintext">
                        L'exercice de ces droits se fait à titre gratuit. Toutefois, en cas de demande manifestement infondée ou excessive, Dropora SAS se réserve la possibilité d'exiger le paiement de frais tenant compte des coûts administratifs, ou de refuser de donner suite à ces demandes.
                    </div>
                    <div className="text">
                        13. Droits concernant les données personnelles des Clients
                    </div>
                    <div className="mintext">
                        Dropora SAS permet à ses Clients de créer des campagnes digitales (Ci-après « la Campagne »). Ces campagnes de marketing interactif permettent aux Clients de collecter des KPI (Un indicateur clé de performance) pour visualiser le nombre d'interaction sur leur campagnes marketing. Les Clients n'auront pas accès à vos données personnelles sans votre consentement.
                    </div>
                    <div className="text">
                        14. Droits et obligations de Dropora SAS
                    </div>
                    <div className="mintext">
                        Dropora SAS ne collecte et ne traite les données personnelles des Utilisateurs (avec leurs consentements) que dans le cadre de la création, l'organisation et la gestion de la Campagne créée par le Client et uniquement à cette fin.
                    </div>
                    <div className="mintext">
                        Dans ce cadre, Dropora SAS effectuera les opérations suivantes :
                        <br />
                        - Hébergement et stockage des données de participation et visualisation des KPI dans la plateforme Dashboard<br />
                        - Analyse statistique (non individuelle) pour permettre le suivi des performances de la Campagne<br />
                        - Blocage nécessaire des participations suivant la géolocalisation de l'adresse IP et l'email de l'Utilisateur<br />
                        - Envoi d'un email pour vérifier l'identité de l'Utilisateur (en option, actif seulement si l'Utilisateur y a souscrit)<br />
                        - Audit des données collectées et vérification de leur conformité (sur demande de l'utilisateur)
                    </div>
                    <div className="mintext">
                        Dropora SAS s'engage à ne traiter les données personnelles des Utilisateurs que conformément aux instructions fournies par l'Utilisateur dans la Charte de confidentialité.
                    </div>
                    <div className="mintext">
                        Dropora SAS garantit la sécurité et la confidentialité des données personnelles des Utilisateurs qu'elle traite pour le compte du Client, et s'engage à mettre à disposition de l'Utilisateur, sur sa demande, les informations concernant les mesures de sécurité techniques et organisationnelles mises en œuvre pour ces traitements.
                    </div>
                    <div className="mintext">
                        Dropora SAS s'engage à notifier à l'Utilisateur en tant que responsable de traitement toute violation des données personnelles des Visiteurs, dans les meilleurs délais après en avoir pris connaissance. Cette notification est accompagnée de toute documentation utile de nature à permettre à l'Utilisateur, si nécessaire, de notifier cette violation à la CNIL
                    </div>
                    <div className="mintext">
                        Dans les cas où Dropora SAS a recours à des sous-traitants situés dans des pays offrant des niveaux de protection non équivalents au niveau de protection des données personnelles dans l'Union Européenne, Dropora SAS s'engage à encadrer ce transfert par la signature de clauses contractuelles types établies par la Commission Européenne.
                    </div>
                    <div className="mintext">
                        Dropora SAS tient à disposition des Utilisateurs qui en font la demande la liste de ses sous-traitants.
                    </div>
                    <div className="text">
                        15. Protection des enfants
                    </div>
                    <div className="mintext">
                        Les utilisateurs du Site âgés de moins de dix-huit (18) ans ne sont pas autorisés à communiquer des données personnelles ni à utiliser les espaces de discussion publics sans le consentement de leurs parents ou tuteurs. Si des mineurs divulguent des données personnelles sur le Site ou sur les zones de discussion publique, ils pourront potentiellement recevoir des messages non sollicités de la part de tiers. En conséquence, il vous revient de vous assurer qu'ils respectent les Conditions Générales d'utilisations applicables, de les mettre en garde sur le partage de données personnelles et de contrôler l'utilisation qu'ils font du Site.
                    </div>
                    <div className="text">
                        16. Divisibilité des clauses
                    </div>
                    <div className="mintext">
                        La nullité d'une des clauses des présentes n'entraîne pas la nullité des présentes Conditions Générales d'Utilisation, les autres clauses continueront à produire leurs effets.
                    </div>
                    <div className="text">
                        17. Modification de la Charte
                    </div>
                    <div className="mintext">
                        Nous nous réservons le droit de modifier la Charte à tout moment. Il est vous est donc recommandé de la consulter régulièrement. En cas de modification, nous publierons ces changements sur cette page et aux endroits que nous jugerons appropriés en fonction de l'objet et de l'importance des changements apportés. Votre utilisation du Site après toute modification signifie que vous acceptez ces modifications. Si vous n'acceptez pas certaines modifications substantielles apportées à la présente Charte, vous devez arrêter d'utiliser le Site.
                    </div>
                    <div className="text">
                        18. Propriété intellectuelle
                    </div>
                    <div className="mintext">
                        Tous les logiciels, codes-sources, dessins, textes, images, enregistrement sonores, animations, marques (y compris le nom « Dropora » et le logo Dropora) et les autres œuvres incluses dans l'Application mobile sont protégés par des droits de propriété intellectuelle et appartiennent à Dropora SAS. Ces droits sont réservés et aucun droit de propriété ne sera transféré aux Utilisateurs ou à des tiers.
                    </div>
                    <div className="mintext">
                        Dropora vous concède un droit personnel d'utilisation de l'Application mobile et de son contenu. Ce droit est accordé à titre non exclusif, révocable, non cessible, non transférable, mondial et gratuit et pour la seule utilisation de l'Application mobile, à l'exclusion de toute autre finalité. Il est strictement interdit d'accéder et/ou d'utiliser et/ou de tenter ou d'utiliser les codes sources ou objets de l'Application mobile. L'Utilisateur n'acquière aucun droit de propriété intellectuelle sur l'Application ou son contenu, ni aucun autre droit que ceux conférés par les présentes CGU. La reproduction des documents figurant sur l'Application mobile est uniquement autorisée aux fins exclusives d'information pour un usage strictement personnel et privé.
                    </div>
                    <div className="text">
                        19. Résiliation
                    </div>
                    <div className="mintext">
                        Dropora peut décider de la fin de la mise à disposition de l'Application mobile à tout moment ; la notification de la fin de mise à disposition se fera par e-mail ou par affichage d'un message sur un écran de l'Application mobile.
                    </div>
                    <div className="mintext">
                        Dropora n'a pas à motiver sa décision de mettre fin à la mise à disposition de l'Application mobile, et cette décision ne peut donner lieu à une indemnisation ni à un remboursement (l'Application mobile étant gratuite).
                    </div>
                    <div className="mintext">
                        Au moment de la résiliation, les droits accordés à l'Utilisateur seront résiliés et ce dernier devra cesser toute utilisation de l'Application.
                    </div>
                    <div className="text">
                        20. Non-renonciation
                    </div>
                    <div className="mintext">
                        Le fait pour l'une des parties de ne pas se prévaloir d'un manquement par l'autre partie à l'une quelconque des obligations visées dans les présentes CGU ne saurait être interprété pour l'avenir comme une renonciation à l'obligation en cause.
                    </div>
                    <div className="text">
                        21. Langue
                    </div>
                    <div className="mintext">
                        Dans l'hypothèse d'une traduction des présentes conditions générales dans une ou plusieurs langues, la langue d'interprétation sera la langue française en cas de contradiction ou de contestation sur la signification d'un terme ou d'une disposition.
                    </div>
                    <div className="text">
                        22. Loi applicable et juridiction
                    </div>
                    <div className="mintext">
                        La conclusion, l'interprétation et la validité des présentes CGU et l'ensemble des contrats qui en découlent et leurs suites sont soumis à la Loi et la Réglementation française en vigueur, à l'exclusion de tout autre législation, quelle que soit la nationalité de l'Utilisateur, il en est ainsi pour les règles de fond et de formes.
                    </div>
                    <div className="mintext">
                        Pour toutes contestations qui s'élèveraient entre l'Utilisateur et la Société relativement à la formation, l'interprétation et à l'exécution des présentes, les Parties s'efforceront de parvenir à un accord amiable. À défaut d'accord amiable, dans le délai de 30 (trente) jours.
                    </div>
                    <div className="mintext">
                        Toute réclamation sera donc au préalable porté par écrit auprès de Dropora, avec les pièces justificatives par email à <a href="mailTo:contact@dropora.com">contact@dropora.com</a>
                    </div>
                    <div className="mintext">
                        À défaut d'accord amiable, les parties conviennent que les tribunaux de Marseille seront exclusivement compétents pour en juger, sauf règles de procédure impératives contraires.
                    </div>
                    <div className="text">
                        23. Contactez-nous
                    </div>
                    <div className="mintext">
                        Si vous avez des questions, des commentaires ou des préoccupations au sujet de nos pratiques de confidentialité, veuillez nous contacter en utilisant les coordonnées suivantes :
                    </div>
                    <div className="mintext">
                        - Contactez-nous par courriel à <a href="mailTo:contact@dropora.com">contact@dropora.com</a>
                    </div>
                </div>
            </div>
            <Footer color={"white"} />
        </>
    );
}

export default Privacy;
