import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/Home";
import CGU from './pages/CGU';
import Privacy from './pages/Privacy';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount = () => {
  }

  render() {
    return (
      <div className="page">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cgu" element={<CGU />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default withTranslation()(App)