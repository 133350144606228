import { useTranslation } from 'react-i18next'

function Footer({color}) {
  const { t } = useTranslation()

  return (
      <div className="legal" style={{color: color || "black"}}>
        {t('legal')}
        <br/>
        <strong><a href={"/cgu"} style={{textDecoration: 'none', color: color || "black"}}>Conditions générales d'utilisation</a></strong> | <strong><a href={"privacy"} style={{textDecoration: 'none', color: color || "black"}}>Politique de confidentialité</a></strong>
        <br />
        Copyright @Dropora {new Date().getFullYear()}
      </div>
  );
}

export default Footer;
